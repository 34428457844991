import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import axios from 'axios'
import Cleave from 'cleave.js/react'
import Analytics from 'src/utils/Analytics'
import {
  SubmitButton,
  PhoneInput,
  Header,
  TextBox,
  Description,
  FormRow,
  LogoRow,
  AndroidContainer,
  AppleContainer,
  DownloadButton,
  DigitalButton,
  FireLogoContainer,
  FlexBox,
} from './styles'

const DigitalForm = ({ page, header, secondaryHeader, text, section }) => {
  const { androidLogo, appleLogo, redFireTvLogo } = useStaticQuery(graphql`
    query DigitalFormQuery {
      androidLogo: file(relativePath: { eq: "digital/logos/android.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      appleLogo: file(relativePath: { eq: "digital/logos/apple.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      redFireTvLogo: file(relativePath: { eq: "digital/logos/fire-red.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const disableSubmit = phoneNumber.length !== 12
  const HEADER_OVERRIDE = {
    'digital-fire': 'Display your favorite',
  }
  const SECONDARY_OVERRIDE = {
    'digital-fire': 'photos on your',
  }
  const TEXT_OVERRIDE = {
    'digital-fire': `Skylight Digital is a Fire device app that turns your TV into a digital photo frame.`,
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    Analytics.track(`Added Digital to Cart`)
    if (disableSubmit) return
    setSubmitted(true)
    axios({
      method: 'post',
      url: 'https://app.ourskylight.com/api/sms_digital_deep_link',
      data: {
        phone_number: phoneNumber,
      },
    })
  }
  const handleRedirectDigitalDeepLink = async (e) => {
    e.preventDefault()
    Analytics.track(`Added Digital to Cart`)
    window.location.href = 'https://skylightfamily.page.link/digital'
  }

  return (
    <TextBox albums={section === 'albums'}>
      <Header albums={section === 'albums'}>{HEADER_OVERRIDE[page] || header}</Header>
      {page === 'digital-fire' ? (
        <FlexBox>
          <Header albums={section === 'albums'}>
            {SECONDARY_OVERRIDE[page] || secondaryHeader}
          </Header>
          <FireLogoContainer>
            <GatsbyImage
              image={redFireTvLogo.childImageSharp.gatsbyImageData}
              alt="Photo of baby in fields on a tv screen."
            />
          </FireLogoContainer>
          <Header>TV</Header>
        </FlexBox>
      ) : (
        <Header albums={section === 'albums'}>{secondaryHeader}</Header>
      )}
      <Description albums={section === 'albums'}>{TEXT_OVERRIDE[page] || text}</Description>
      <DownloadButton hideOnDesktop type="button" onClick={handleRedirectDigitalDeepLink}>
        Download Now
      </DownloadButton>
      {submitted ? (
        <Description albums={section === 'albums'} hideOnMobile>
          Thanks! You should receive a text shortly!
        </Description>
      ) : (
        <>
          <FormRow albums={section === 'albums'}>
            <PhoneInput onSubmit={handleSubmit}>
              <Cleave
                type="phone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="form-control"
                options={{ numericOnly: true, blocks: [3, 3, 4], delimiter: '-' }}
                placeholder="999-999-9999"
              />
            </PhoneInput>
            <SubmitButton type="button" onClick={handleSubmit} disabled={disableSubmit}>
              Send Link
            </SubmitButton>
          </FormRow>
          <LogoRow albums={section === 'albums'}>
            <DigitalButton type="button" onClick={handleRedirectDigitalDeepLink}>
              <AndroidContainer>
                <GatsbyImage
                  image={androidLogo.childImageSharp.gatsbyImageData}
                  alt="Display of photos uploading on the Skylight app."
                />
              </AndroidContainer>
            </DigitalButton>
            <DigitalButton type="button" onClick={handleRedirectDigitalDeepLink}>
              <AppleContainer>
                <GatsbyImage
                  image={appleLogo.childImageSharp.gatsbyImageData}
                  alt="Photo of baby in fields on a tv screen."
                />
              </AppleContainer>
            </DigitalButton>
          </LogoRow>
        </>
      )}
    </TextBox>
  )
}

DigitalForm.propTypes = {
  page: PropTypes.string,
  header: PropTypes.string,
  secondaryHeader: PropTypes.string,
  text: PropTypes.string,
  section: PropTypes.string,
}
export default DigitalForm
